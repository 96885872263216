<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
      <span>
        <b v-if="data.id != null">Edit {{ title }}</b>
        <b v-else>Create {{ title }}</b>
      </span>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Name"
            v-validate="'required'"
            name="Name"
            v-model="data.name"
          />
          <span class="text-danger text-sm" v-show="errors.has('Name')">{{
            errors.first("Name")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Notes"
            name="Notes"
            v-model="data.notes"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Status</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isActive"
          >
            <span slot="on">Active</span>
            <span slot="off">Inactive</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleClose"
            >Close</vs-button
          >
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      id: {
        type: Number,
      },
      workTeamId: {
        type: Number,
      },
      baseUrl: {
        type: String,
      },
      title: {
        type: String,
      },
    },
  
    data() {
      return this.initialState();
    },
    methods: {
      initialState() {
        return {
          data: {
            id: null,
            territoryAreaId: null,
            name: "",
            notes: "",
            isActive: true,
          },
          territoryArea: {
            id: null,
            code: "",
            name: "",
            type: "",
          },
        };
      },
      handleSubmit() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$vs.loading();
            if (this.data.id) {
              this.putData();
            } else {
              this.postData();
            }
          }
        });
      },
      paramData() {
        var isActive = "1";
  
        if (this.data.isActive) {
          isActive = "1";
        } else {
          isActive = "0";
        }
        return {
          territory_area_id: this.territoryArea.id,
          name: this.data.name,
          notes: this.data.notes,
          is_active: isActive,
        };
      },
      postData() {
        this.$http
          .post(this.baseUrl, this.paramData())
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "New Data Created",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      putData() {
        this.$http
          .put(this.baseUrl + "/" + this.data.id, this.paramData())
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Data Updated",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      handleClose() {
        window.scrollTo(0, 0);
        this.$emit("close");
      },
      getData() {
        this.$vs.loading();
        if (this.data.id) {
          this.$http
            .get(this.baseUrl + "/" + this.data.id)
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.data.id = resp.data.ID;
                this.territoryArea.id = resp.data.territory_area_id;
                this.data.name = resp.data.name;
                this.data.notes = resp.data.notes;
                if (resp.data.is_active == "1") {
                  this.data.isActive = true;
                } else {
                  this.data.isActive = false;
                }
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      },
      setTerritoryArea(val) {
        this.territoryArea = val;
      },
    },
    mounted() {
      if (this.id) {
        this.data.id = this.id;
        this.getData();
      }
    },
    computed: {},
    watch: {
      id(val) {
        if (val) {
          this.data.id = this.id;
          this.getData();
        } else {
          this.data.id = null;
        }
      },
    },
  };
  </script>
  